import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";

import {Helmet} from "react-helmet"; 

import { Col, Row, Form, Button, Divider } from "antd";
import { FundViewOutlined } from "@ant-design/icons";
import { Navbar } from "../../components/Navbar";
import Footer from "../../components/Footer";
import GetStartedButton from "../../components/GetStartedButton";
import { SectionBanner } from "../../components/HomeComponents";

import "./HowWeCanHelp.css";

import DashboardImg from "../../assets/images/how-we-can-help/dashboard.png";
import MyDashboardImg from "../../assets/images/how-we-can-help/my-dashboard.png";
import MobileAppFlashScreenImg from "../../assets/images/how-we-can-help/mobile-app-flash-screen.png";
import CheckedBox from "../../assets/images/how-we-can-help/checked-box.png";
import ForCaregiversImg from "../../assets/images/how-we-can-help/for-caregivers.jpg";
import ForYourselfImg from "../../assets/images/how-we-can-help/for-yourself.jpg";
import ForPartnersImg from "../../assets/images/how-we-can-help/for-partners.jpg";

import SafeSecurePrivacyImg from "../../assets/images/how-we-can-help/safe-secure-private-illustration.png";
import CareJournalImg from "../../assets/images/how-we-can-help/care-journal-illustration.png";
import CarePlanImg from "../../assets/images/how-we-can-help/care-plan-illuatrtion.png";
import CareCircleImg from "../../assets/images/how-we-can-help/care-circles-illustration.png";
import CareConciergeImg from "../../assets/images/how-we-can-help/care-concierge-illustration.png";

import { mclOpen } from "../../api/Mcl";
import { Url } from "../../constants/Global";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import _ from "lodash";

import { ScheduleModal } from "../../components/ScheduleForm";
import {
  DemoRequestModal,
  DemoRequestForm,
} from "../../components/DemoRequestForm";
import { openNotification } from "../../components/Various";

const ForSBItem = ({
  id = "",
  className = "",
  img = "",
  alt = "",
  header = "",
  list = [],
}) => {
  return (
    <Row className={className} gutter={24}>
      {/* Anchor */}
      <span id={id} className="absolute top-[-150px]"></span>
      <Col md={10}>
        <img src={img} className="w-full" alt={alt} />
      </Col>
      <Col md={14} className="mt-4 md:mt-0">
        <div className="flex flex-auto flex-col self-stretch">
          <h5 className="text-h4 text-primary-purple font-normal">{header}</h5>
          <ul className="pl-4 mt-2">
            {list.map((item) => (
              <li key={item} className="text-primary-purple text-xl">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

const WhyUsItem = ({
  id = "",
  className = "",
  img = "",
  alt = "",
  header = "",
  text = "",
}) => {
  return (
    <Row className={className}>
      {/* Anchor */}
      <span id={id} className="absolute top-[-150px]"></span>
      <Col xs={24} md={12} className="flex justify-center">
        <img
          src={img}
          className="max-h-[200px] lg:max-h-[250px] xl:max-h-[400px]"
          alt={alt}
        />
      </Col>
      <Col xs={24} md={12}>
        <div className="flex flex-auto flex-col self-stretch gap-1 h-full justify-center">
          <h5 className="text-h4 font-normal text-primary-purple text-center md:text-start">
            {header}
          </h5>
          <p className="text-lg font-normal text-primary-purple text-center md:text-start">
            {text}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export const HowWeCanHelp = () => {
  const navigate = useNavigate();
  const [scheduleModalSetting, setScheduleModalSetting] = useState({
    title: "Schedule a Free Consultation",
    isModalVisible: false,
  });
  const [demoRequestModalSetting, setDemoRequestModalSetting] = useState({
    title: "Request Mobile App Demo",
    isModalVisible: false,
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [demoRequestForm] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("needHelpForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const submitScheduleForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "how_we_can_help",
      action: "submit_form",
      label: "schedule_form",
    });

    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "schedule"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            if (
              !_.isEmpty(res.data) &&
              _.includes(res.data, "https://calendly.com")
            ) {
              window.open(res.data, "_blank");
            } else {
              openNotification({
                message: "Sorry",
                status: "500",
                title: "Something goes wrong in the server",
                subTitle: "Please wait for the staff to fix it.",
              });
            }
          } else {
            openNotification({
              message: "Sorry",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          setSubmitting(false);
          form.resetFields();
        })
        .catch((error) => {
          openNotification({
            message: "Sorry",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  const submitDemoRequestForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "how_we_can_help",
      action: "submit_form",
      label: "demo_request_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "demo-request"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            openNotification({
              message: "Demo request submitted",
              status: "success",
              title: "Thank you " + data.firstName + "!",
              subTitle:
                "Thank you requesting a demo. We will follow up with you soon.",
            });
          } else {
            openNotification({
              message: "Sorry...Demo Request encountered an error",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setDemoRequestModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          demoRequestForm.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry...Demo Request encountered a server error",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Demo Request failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>My CareLinq - How We Can Help</title>
          <meta name="description" content="Whether you are an individual planning your own care, or a family member, or a friend giving care, My CareLinq is the resource to support you throughout the process of preparing and planning for aging at home, illness, end-of-life, and after-life care." />
          <link rel="canonical" href="https://mycarelinq.com/how-we-can-help" />
      </Helmet>
      <ScheduleModal
        setting={scheduleModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "how_we_can_help",
            action: "close_modal",
            label: "schedule_form",
          });
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitScheduleForm}
        form={form}
      />
      <DemoRequestModal
        setting={demoRequestModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "how_we_can_help",
            action: "close_modal",
            label: "demo_request_modal",
          });
          setDemoRequestModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitDemoRequestForm}
        form={demoRequestForm}
      />
      <Navbar signin={true} navigate={navigate} />
      {/* <section
        className="
                bg-[url('assets/images/about-us/header-bg.png')] 
                min-h-[2rem]
                max-h-[12rem]
                bg-center 
                bg-no-repeat 
                bg-cover 
                overflow-hidden 
                flex
            "
      >
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
          <Row>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                A PLATFORM &amp; SERVICE FOR EVERYONE
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider /> */}
      {/* Dashboard Section */}
      <section>
        <div className="lg:container lg:mx-auto w-full px-4">
          <Row className="min-h-[150px] pb-5">
            <p className="text-[#ef1f1e] text-center xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.25rem]">
              We are responding to a high volume of inquiries from family
              caregivers who are seeking compensation or program support to
              offset out of pocket costs. Please click{" "}
              <span>
                <Button
                  type="danger"
                  shape="round"
                  className="text-highlight xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.25rem] py-0 px-1"
                  onClick={() => {
                    ReactGA.event({
                      category: "footer",
                      action: "open_modal",
                      label: "schedule_modal",
                    });
                    setScheduleModalSetting((prevState) => ({
                      ...prevState,
                      isModalVisible: true,
                    }));
                  }}
                >
                  schedule
                </Button>
              </span>{" "}
              for a free appointment with us so that we can begin helping you
              determine eligibility.{" "}
            </p>
            <Col
              xs={24}
              className="flex items-center justify-center"
              style={{ borderBottom: "1.4px solid #30225F" }}
            >
              <h1 className="xl:text-[3rem] lg:text-[2.25rem] md:text-[1.75rem] sm:text-[1.5rem] xs:text-[1rem] text-center text-primary-purple">
                A Platform &amp; Service for Everyone
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="flex items-center">
              <p className="text-primary-purple text-center xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.25rem]">
                Whether you are an{" "}
                <strong className="font-bold">
                  individual planning your own care
                </strong>
                , or a <strong className="font-bold">family</strong> member, or
                a <strong className="font-bold">friend</strong> giving care,{" "}
                <strong className="font-bold">My CareLinq</strong> is the
                resource to support you throughout the process of preparing and
                planning for aging at home, illness, end-of-life, and after-life
                care.
              </p>
            </Col>
          </Row>
          <Row
            className="mb-5 pb-5 flex items-center justify-center"
            // style={{ borderBottom: "1.4px solid #30225F" }}
          >
            <Col xs={24} lg={18} xl={16} className="flex items-center pr-8">
              <img
                src={DashboardImg}
                className="w-full"
                alt="Dashboard showcase"
              />
            </Col>
            <Col
              xs={24}
              lg={6}
              xl={8}
              className="pl-8"
              style={{ borderLeft: "1.8px solid #6c638d" }}
            >
              <div className="flex flex-auto flex-col self-stretch h-full">
                <div className="flex flex-auto flex-col self-stretch pl-2 lg:text-md text-lg">
                  <p className="text-primary-purple">
                    <strong className="font-bold">QUICK LINKS</strong>
                    <br />
                    <br />
                  </p>
                  <a
                    href="/#for-family-caregivers"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    My CareLinq for Caregivers
                  </a>
                  <a
                    href="/#for-yourself"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    My CareLinq for Yourself
                  </a>
                  <a
                    href="/#for-partners"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    My CareLinq for Partners
                  </a>
                  <a
                    href="#secure-data"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    Secure Data
                  </a>
                  <a
                    href="#life-care-journal"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    Life Care Journal
                  </a>
                  <a
                    href="#care-plan"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    Care Plan
                  </a>
                  <a
                    href="#care-circle"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    Care Circles
                  </a>
                  <a
                    href="#care-concierge"
                    className="text-lg lg:text-md text-primary-purple mb-5"
                  >
                    Care Concierge
                  </a>
                </div>
                <GetStartedButton
                  className="mr-0 ml-0 mt-0 mb-0 xs:text-[1rem] sm:text-[1rem] md:text-[1rem] lg:text-[1rem] px-2 lg:w-[flex]"
                  style={{ padding: "5px 16px" }}
                  // shape="round"
                  onClick={() => {
                    ReactGA.event({
                      category: "how_we_can_help",
                      action: "click_button",
                      label: "create_account",
                    });
                    // navigate("/survey");
                    navigate("/register");
                  }}
                >
                  CREATE ACCOUNT
                </GetStartedButton>
              </div>
            </Col>
          </Row>
        </div>
        <section className="m-0 p-0 overflow-hidden">
          <SectionBanner
            message="My CareLinq is a collaborative care and support management
                  platform that integrates planning templates, resources, case management, and supportive help to make the process of caring easier."
          />
        </section>
      </section>
      {/* Product Roadmap */}
      <section className="bg-[url('assets/images/background-bubbles.png')] bg-center bg-no-repeat bg-cover overflow-hidden flex flex-col">
        <div
          className={
            "w-full mt-0 mx-0 px-0 px-5 sm:px-8 md:px-10 lg:px-12 xl:px-16"
          }
        >
          <h1 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5">
            Product Roadmap
          </h1>
          <p className="text-primary-purple text-center xs:text-[0.7rem] sm:text-[0.8rem] md:text-[1rem] lg:text-[1.25rem]">
            We are on a journey to create next generation tools for individuals
            and caregivers that are accessible.
          </p>
        </div>
        <div>
          <Row className="justify-center flex">
            <Col
              md={24}
              lg={12}
              className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
            >
              <Divider style={{ borderColor: "#8159f580", borderWidth: "3px" }}>
                <h3 className="text-primary-purple">
                  <strong>Website</strong> (Today)
                </h3>
              </Divider>
              <Row>
                <Col
                  xl={24}
                  className="max-h-[400px] mx-auto items-center justify-center flex"
                >
                  <img
                    src={MyDashboardImg}
                    alt="Caregiver dashboard for care circle"
                    className="mx-auto max-h-[300px] items-center justify-center"
                    // style={{ height: "50%", filter: "opacity(0.2)" }}
                  ></img>
                </Col>
              </Row>
              <Row className="flex justify-center items-center mt-3">
                <Col xs={2}>
                  <img src={CheckedBox} alt="checked box" />
                </Col>
                <Col xs={7} className="tracking-tighter">
                  Learning from our customers
                </Col>
                <Col xs={2}>
                  <img src={CheckedBox} alt="checked box" />
                </Col>
                <Col xs={5} className="tracking-tighter">
                  Rapid prototyping
                </Col>
                <Col xs={2}>
                  <img src={CheckedBox} alt="checked box" />
                </Col>
                <Col xs={6} className="tracking-tighter">
                  Gathering requirements
                </Col>
              </Row>
            </Col>
            <Col
              md={24}
              lg={12}
              className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
            >
              <Divider style={{ borderColor: "#8159f580", borderWidth: "3px" }}>
                <h3 className="text-primary-purple">
                  <strong>Mobile App</strong> (Next)
                </h3>
              </Divider>
              <Row>
                <Col
                  xl={24}
                  className="max-h-[400px] mx-auto items-center justify-center flex"
                >
                  <img
                    src={MobileAppFlashScreenImg}
                    alt="Caregiver mobile app"
                    className="mx-auto max-h-[300px] items-center justify-center"
                    // style={{ height: "50%", filter: "opacity(0.2)" }}
                  ></img>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={24} className="py-2 flex justify-center items-center">
                  <Button
                    type="primary"
                    shape="round"
                    icon={<FundViewOutlined />}
                    onClick={() => {
                      ReactGA.event({
                        category: "how_we_can_help",
                        action: "open_modal",
                        label: "mobile_demo_modal",
                      });
                      setDemoRequestModalSetting((prevState) => ({
                        ...prevState,
                        isModalVisible: true,
                      }));
                    }}
                    aria-controls="request-mobile-app-demo"
                    aria-expanded={1}
                    size="md"
                  >
                    Request Demo
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* <div className="lg:container lg:mx-auto w-full px-4 mt-10">
          <ForSBItem
            id="for-caregiver"
            className="mb-8 relative"
            img={ForCaregiversImg}
            alt={"For caregiver photo"}
            header={
              <span>
                FOR <span className="font-bold">CAREGIVERS</span>
              </span>
            }
            list={[
              `Secure, private, and safe digital
                            collaboration for effective management of
                            caregiving responsibilities.`,
              `Centralize important health, medical,
                            legal, financial, social, and after-life care
                            documents.`,
              `Manage the plan, team, and purchasing
                            decisions over time as care needs change.`,
            ]}
          />
          <ForSBItem
            id="for-yourself"
            className="mb-8 relative"
            img={ForYourselfImg}
            alt={"For yourself photo"}
            header={
              <span>
                FOR <span className="font-bold">YOURSELF</span>
              </span>
            }
            list={[
              `Take control of your legacy and create a 
                            plan that reflects who you are and what 
                            you want.`,
              `Document your wishes, intentions, support 
                            needs, and thanks to those who matter the 
                            most.`,
              `Trust that your wishes will be safeguarded 
                            and implemented to the best of your care 
                            team’s ability. `,
            ]}
          />
          <ForSBItem
            id="for-partners"
            className="mb-8 relative"
            img={ForPartnersImg}
            alt={"For partner photo"}
            header={
              <span>
                FOR <span className="font-bold">PARTNERS</span>
              </span>
            }
            list={[
              `We want to partner with local and 
                            specialized community resources, vendors, 
                            providers, and referral networks that 
                            provide excellent service. `,
              `Enable trusted My CareLinq suppliers to 
                            grow and be a part of our collective success.`,
              `Establish and grow a network of trusted 
                            partners that stands apart.`,
            ]}
          />
        </div> */}
      </section>
      {/* Purple Banner */}
      <section className="m-0 p-0 overflow-hidden">
        <SectionBanner message="Try My CareLinq's platform and services for free today." />
      </section>
      {/* Why us section */}
      <section className="my-8">
        <h1 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem]">
          Product Features
        </h1>
        <div className="lg:container lg:mx-auto w-full px-4">
          <WhyUsItem
            id="secure-data"
            className="How-we-can-help-why-us-item relative"
            img={SafeSecurePrivacyImg}
            alt={"Safe secure privacy illustration"}
            header={
              <span>
                SAFE, <strong className="font-bold">SECURE</strong> &amp;
                PRIVATE
              </span>
            }
            text={
              <span>
                We are committed to our customers’ privacy, and back that up
                with end-to-end encryption and a unique system where the keys
                are created and controlled on your own devices.{" "}
                <span className="font-bold">
                  Nobody but you – and the people you personally invite and
                  authenticate – can access your data.
                </span>
              </span>
            }
          />
          <WhyUsItem
            id="life-care-journal"
            className="How-we-can-help-why-us-item relative"
            img={CareJournalImg}
            alt={"Care journal illustration"}
            header={
              <span>
                YOUR <strong className="font-bold"> LIFE CARE JOURNAL</strong>
              </span>
            }
            text={
              <span>
                Completely private corner where individuals can reflect,
                process, and write without any judgement or hesitation about the
                unknown. Find and engage with support and resources to help
                inform your process.
                <span className="font-bold"> For your eyes only.</span>
              </span>
            }
          />
          <WhyUsItem
            id="care-plan"
            className="How-we-can-help-why-us-item relative"
            img={CarePlanImg}
            alt={"Care plan illustration"}
            header={
              <span>
                YOUR <strong className="font-bold">CARE PLAN</strong>
              </span>
            }
            text={
              <span>
                Comprehensive and secure life care plan designed to document
                many aspects of what future caregivers and survivors need to
                manage, implement instructions, and close out one’s life. This
                is more than an Advanced Directive or a Will. Our lives are
                complex, dynamic and nuanced and so{" "}
                <span className="font-bold">
                  we’ve created a tool that can be customized to your needs and
                  your care team needs.
                </span>{" "}
                A tool that can be selectively shared or passed on when an
                individual is no longer able.
              </span>
            }
          />
          <WhyUsItem
            id="care-circle"
            className="How-we-can-help-why-us-item relative"
            img={CareCircleImg}
            alt={"Care circle illustration"}
            header={
              <span>
                YOUR <strong className="font-bold"> CARE CIRCLE</strong>
              </span>
            }
            text={
              <span>
                You choose who you want in your care circle. We understand as
                life events unfold and circumstances change, people you choose
                to invite in also might change.{" "}
                <span className="font-bold">
                  As the account owner, you select permission levels, who can do
                  what, and leave instructions.
                </span>
              </span>
            }
          />
          <WhyUsItem
            id="care-concierge"
            className="How-we-can-help-why-us-item relative"
            img={CareConciergeImg}
            alt={"Care concierge illustration"}
            header={
              <span>
                YOUR <strong className="font-bold"> CARE CONCIERGE</strong>
              </span>
            }
            text={
              <span>
                Our dedicated Care Concierges are a great resource to
                <span className="font-bold">
                  {" "}
                  help individuals set goals, strategy, tactics, and coordinate
                  research and implementation of resources.
                </span>{" "}
                We serve as a thought partner, advocate, researcher, project
                manager, coordinator and resource finder for just about anything
                that comes your way.
              </span>
            }
          />
          <Row>
            <Col xs={24} className="mt-8 flex justify-center">
              <GetStartedButton
                style={{ padding: "16px 24px" }}
                onClick={() => {
                  ReactGA.event({
                    category: "how_we_can_help",
                    action: "click_button",
                    label: "create_account",
                  });
                  // navigate("/survey");
                  navigate("/register");
                }}
              >
                CREATE ACCOUNT
              </GetStartedButton>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </main>
  );
};
