import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";

import {
  Button,
  // Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Result,
  Row,
} from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import AuthContext from "../../context/AuthProvider";

import { Url, RoleToRoute } from "../../constants/Global";
import axios from "../../api/axios";
import { mclOpen } from "../../api/Mcl";

import "../../components/Navbar.css";
import "./Login.css";
import useAuth from "../../hooks/useAuth";
import GAText from "../../components/GAText";

import logo from "../../assets/images/logos/mcl-logo-color.svg";
import logoText from "../../assets/images/logos/mcl-logo-white-horizontal-text-only-new.png";

// meta pixel integration
import MetaPixel from "../../api/metaPixel";

import { AUTH_KEY, AUTH_KEY_TTL } from "../../constants/Global";  

const { Content, Footer, Header } = Layout;

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: (
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        /* extra={<Button type="primary">Back Home</Button>}*/
      />
    ),
  });
};

function setWithExpiry(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

const navigateSwitch = (roles, navigate) => {
  const destin = RoleToRoute(Math.max(...roles));
  navigate(destin, { replace: true });
};

const navigateSurvey = (navigate) => {
  navigate('/dosurvey', { replace: true });
};

export const Login = () => {
  const navigate = useNavigate();
  // const { setAuth } = useContext(AuthContext);
  const { setAuth } = useAuth();
  const { INVITATION_ID } = useParams();
  const [modalVisible, setModalVisible] = useState(true);

  const onFinish = async (values) => {
    const email = values.email;
    const password = values.password;
    // console.log('Received values of form: ', values);
    try {
      // console.log('login url:', Url.LOGIN);
      const response = await mclOpen.post(
        Url.LOGIN,
        JSON.stringify({
          email: email,
          password: password,
          invitationID:
            INVITATION_ID && INVITATION_ID != 1 ? INVITATION_ID : "",
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // console.log('data:' + JSON.stringify(response?.data));
      // console.log('headers:' + JSON.stringify(response?.headers));
      //console.log(JSON.stringify(response));
      //const headers = response.headers;
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const care = response?.data?.care;
      const id = response?.data?.id;
      const b2b = response?.data?.b2b;
      // setAuth({ id, email, password, care, roles, accessToken })
      const user = { id, email, password, care, roles, accessToken, b2b }; 
      const now = new Date();
      const expiry = now.getTime() + AUTH_KEY_TTL
      const authState = {id, care, roles, accessToken, expiry, b2b}; 
      setAuth(user);
      localStorage.setItem(AUTH_KEY, JSON.stringify(authState));

      // setEmail('');
      // setPwd('');
      //setSuccess(true);
      // ?? TODO: navigate to dashboard depending on role
      //navigate('/caregiver/dashboard', { replace: true });
      const dosurvey = response?.data?.dosurvey;
      console.log('@@# dosurvey',dosurvey);
      if (dosurvey) {
        navigateSurvey(navigate);
      } else { 
        navigateSwitch(roles, navigate);
      }
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        // setErrMsg('Missing Username or Password');
        if (err.response.data === "Email address not verified yet.") {
          openNotification({
            message: "",
            status: "error",
            title: "Login failed",
            subTitle:
              "Email has not been verified yet. Please check your email.",
          });
        } else if (err.response.data === "Invalid invitation id.") {
          openNotification({
            message: "",
            status: "error",
            title: "Login failed",
            subTitle:
              "Sorry, The invitation link has been invalidated or revoked, the page will be refresh in 3 seconds.",
          });
          navigate("/site/login");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          openNotification({
            message: "",
            status: "error",
            title: "Login failed",
            subTitle: "Missing Email or Password",
          });
        }
      } else if (err.response?.status === 401) {
        // setErrMsg('Unauthorized');
        openNotification({
          message: "",
          status: "401",
          title: "402",
          subTitle: "Email or password may be incorrect.",
        });
      } else {
        // setErrMsg('Login Failed');
        openNotification({
          message: "Login failed",
          status: "500",
          title: "500",
          subTitle: "Sorry, your login attempt failed.",
        });
      }
      // errRef.current.focus();
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Layout className="login-layout">
      <MetaPixel />
      <Header className="p-0 flex flex-row login-header-navbar">
        <Col span={8}>
          <span className="navbar-brand px-2">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({
                  left: 0,
                  top: 0,
                  behavior: "auto",
                });
              }}
            >
              <img
                className="nav-logo inline-block"
                src={logo}
                // maxHeight="30px"
                width="auto"
                alt="MCL Icon"
              />
              <img
                className="nav-logo-text inline-block"
                src={logoText}
                // maxHeight="20px"
                width="auto"
                // width="134"
                alt="My CareLinq"
              />
            </Link>
          </span>
        </Col>
        <Col span={16} />
      </Header>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", minHeight: "100dvh" }}
      >
        <Modal
          title={"Accept Invitation"}
          visible={modalVisible && INVITATION_ID && INVITATION_ID != 1}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <p>If you have an account, please login to join the circle. </p>
          <p>
            If you don't have an account, please click{" "}
            <span className="text-blue-500">create account</span>.{" "}
          </p>
          <p>
            After you join the circle, please wait for primary caregiver
            verifying your application.
          </p>
        </Modal>
        <Modal
          title={"Please log in or sign up before subscribe"}
          visible={modalVisible && INVITATION_ID && INVITATION_ID == 1}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <p>
            Before signing up, we need a little information about your current
            situation. Please begin our onboarding survey to create your
            account. <strong>Log in</strong> or{" "}
            <strong>click Create Account</strong> to continue.
          </p>
        </Modal>
        <Col>
          <Form
            id="login"
            name="normal_login"
            className="login-form"
            initialValues={{
              email: "",
              password: "",
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email address!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                            Forgot password
                        </a>
                    </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              Or{" "}
              <a
                // href={
                //   INVITATION_ID && INVITATION_ID != 1
                //     ? "/survey/" + INVITATION_ID
                //     : "/survey"
                // }
                href={
                  INVITATION_ID && INVITATION_ID != 1
                    ? "/register/" + INVITATION_ID
                    : "/register"
                }
              >
                Create Account now!
              </a>
              <br />
              Forgot password? Click <a href="/site/forgot-password/">Here</a>
            </Form.Item>
            <GAText></GAText>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
