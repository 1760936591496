import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { PurpleDivider } from "../../components/Various";
import { Navbar } from "../../components/Navbar";
// import Footer from "../../components/Footer";
import ReactGA from "react-ga4";
import {
  Card,
  PageHeader,
  Layout,
  Row,
  Col,
  Steps,
  Form,
  Input,
  Button,
  Space,
  Radio,
  Result,
  notification,
  Select,
  Dropdown,
  Modal, 
  Collapse,
  Checkbox,
} from "antd";
import { DownOutlined } from '@ant-design/icons';

import AnswerContext, { AnswerProvider } from "../../context/AnswerProvider";

import { ToUContent } from "../../components/TermsOfUse";

import { Care, categoryToPage, Url } from "../../constants/Global";
import Missing from "../site/Missing";

// import imgIncare from "../../assets/images/cards/incare.jpeg";
// import imgSelfcare from "../../assets/images/cards/selfcare.jpeg";
// import imgProvider from "../../assets/images/cards/providers.jpeg";
// import surveyBg from "../../assets/images/testimonials-bubbles-bg.svg";
// import Sider from "antd/lib/layout/Sider";

// meta pixel integration
import MetaPixel from "../../api/metaPixel";

import B2bLogo from "../../components/B2bLogo";

import { STARTER_PRODUCT_ID } from "../pricing/Productv3";

import "./Survey.css";

import { mclOpen } from "../../api/Mcl";
import { last } from "lodash";

const { Header, Content, Footer } = Layout;
//const { Meta } = Card;
const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;


export const RegisterRoutes = () => {
  return (
    <AnswerProvider>
      <Routes>
        <Route exact path="/:PRODUCT_ID" element={<Register />} />
        {/* <Route exact path="/:INVITATION_ID" element={<SurveyInvited />} /> */}
        <Route exact path="/invite/:INVITATION_ID" element={<Register />} />
        {/* <Route exact path="/referral" element={<SurveyProvider />} /> */}
        <Route path="/b2b/:B2B_KEY" element={<Register />} />
      </Routes>
    </AnswerProvider>
  );
};

const Register = () => {
  const navigate = useNavigate();
  const { answer, setReason, b2b, setB2b } = useContext(AnswerContext);
  // const [page, setPage] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const { B2B_KEY, INVITATION_ID, PRODUCT_ID } = useParams();
  console.log("b2b_key:", B2B_KEY);

  useEffect(() => {
    if (B2B_KEY) {
      console.log("B2B_KEY:", B2B_KEY);
      mclOpen
        .get(Url.B2B.concat("/", B2B_KEY))
        .then((res) => {
          console.log("b2b:", res.data);
          setB2b(res?.data?.key);
          if (!res?.data?.key) {
            setErrMsg('Invalid business to business availment code!');
          }
        }).catch(error => {
          setErrMsg(error.response.data.message);
        });
    }
  }, []);

  const showContent = (pageSelect) => {
    // console.log('answer:' + JSON.stringify(answer));
    return <RegisterSubscribe product_id={PRODUCT_ID || STARTER_PRODUCT_ID} invitation_id={INVITATION_ID}/>
    // switch (pageSelect) {
    //   case Care.Reason.INCARE:
    //     // console.log('showing in care');
    //     return <SurveyIncare />;
    //   case Care.Reason.SELFCARE:
    //     // console.log('showing selfcare');
    //     return <SurveySelfcare />;
    //   case Care.Reason.PROVIDER:
    //     // console.log('showing provider');
    //     return <SurveyProvider navbar={false} />;
    //   // TODO: INVITEDONLY survey
    //   // case Care.Reason.INVITEDONLY:
    //   //   // console.log('showing provider');
    //   //   return <SurveyInvitedOnly />;
    //   default:
    //     return <SurveyReason setPage={setPage} />;
    // }
  };

  return (
    <Layout
      className="survey-layout"
      style={{ minHeight: "100vh", minHeight: "100dvh" }}
    >
      <Navbar signin={true} navigate={navigate} b2b={b2b}/>
      <MetaPixel />
      <Content className="p-6">
        <B2bLogo b2b={b2b} banner={true}/>
        {/* <h1
          style={{
            fontSize: "xx-large",
            // fontSize: "3em",
            fontWeight: "bold",
            color: "#30225f",
          }}
        >
          What Type Account Do You Need?
        </h1> */}
        <div
          style={{ background: "#eef2f6", marginTop: "0rem", height: "flex" }}
        >
          {showContent(0)}
        </div>
        { errMsg ? 
          <div style={{display: "flex", justifyContent: "center"}}>
              <h1 style={{color:"red"}}>{errMsg}</h1>
          </div>
          : null 
        }
      </Content>
      <Footer className="block fixed w-full bottom-0 text-[0.75rem] text-center font-thin items-center content-center text-primary-purple h-[2rem] p-1.5">
        Copyright &copy; 2021, 2023{" "}
        <a href="https://mycarelinq.com">
          <strong>My CareLinq, Inc.</strong>
        </a>{" "}
        All Rights Reserved.
      </Footer>
    </Layout>
  );
};

const RegisterSubscribe = (
    // { current, setCurrent, select }
    {product_id, invitation_id}
  ) => {
  const { answer, 
          email, setEmail, password, setPassword, 
          phone, setPhone, b2b, setB2b,
          firstName, setFirstName, lastName, setLastName } =
    useContext(AnswerContext);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  const [userTempEmail, setUserTempEmail] = useState("");
  const [userTempStripeID, setUserTempStripeID] = useState("");
  const [userTempSubID, setUserTempSubID] = useState("");
  const [userTempPlan, setUserTempPlan] = useState("");

  const [companies, setCompanies] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [touChecked, setTouChecked] = useState(false);

  const { INVITATION_ID, TEMP_USER_ID } = useParams();

  const DEFAULT_B2B_OTHER = 'other';

  // console.log('subscribe answer:', answer)

  useEffect(() => {
    if (submit) {
      doSubscribe();
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (TEMP_USER_ID) {
      mclOpen
        .get(Url.PAYMENT.concat("/getUserTemp/", TEMP_USER_ID))
        .then((res) => {
          setUserTempEmail(res.data.email);
          setUserTempStripeID(res.data.stripeCustomerID);
          setUserTempSubID(res.data.subscriptionID);
          setUserTempPlan(res.data.currentPlan);
        });
    }
    // load employers list
    mclOpen
    .get(Url.B2B.concat("/"))
    .then((res) => {
      console.log("companies:", res.data);
      const newCompanies = res.data.map(o => ( {label: o.description, key: o.key} ));
      setCompanies(newCompanies);
      //setCompanies(res.data);
      // setB2b(res?.data?.key);
      // if (!res?.data?.key) {
      //   setErrMsg('Invalid business to business availment code!');
      // }
    }).catch(error => {
      // setErrMsg(error.response.data.message);
    });

  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute reCAPTCHA not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);


  const handleModalSubmit = (event) => {
    event.preventDefault();
    setIsModalVisible(false);
    // console.log('gott here!!!', firstName, lastName);
    // // if (!subscribeNew) {
    //   ReactGA.event({
    //     category: 'pricing',
    //     action: 'submit_subscribe',
    //     label: 'upgrade',
    //     nonInteraction: true,
    //   });
    //   mclAuth
    //     .get(Url.PAYMENT.concat("/upgradeStripeSubs/" + props.product_ID))
    //     .then((res) => {
    //       alert(res.data);
    //       mclAuth
    //         .get(Url.PAYMENT.concat("/currentPlan"))
    //         .then((res) => {
    //           if (res.data) {
    //             props.setCurrentPlan(res.data);
    //             mclAuth
    //               .get(Url.PAYMENT.concat("/subExpiration"))
    //               .then((res) => {
    //                 if (res) {
    //                   props.setExpiration(
    //                     res.data === 0
    //                       ? ""
    //                       : ", expiration:" +
    //                       new Intl.DateTimeFormat("en-US").format(
    //                         res.data * 1000
    //                       )
    //                   );
    //                 }
    //               });
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error.message);
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error.message);
    //     });
    // } else {
      const recaptchaFirst = async () => {
        ReactGA.event({
          category: 'pricing',
          action: 'submit_subscribe',
          label: 'new_subscribe',
          nonInteraction: true,
        });
        // console.log('registersubscribe: retrieving recaptcha!');
        let recaptchaToken = await handleReCaptchaVerify();
        // console.log('registersubscribe: recaptcha token', JSON.stringify(recaptchaToken));

        if (recaptchaToken) {
            // console.log('registersubscribe: in posting');
            ReactGA.event({
              category: 'pricing',
              action: 'submit_subscribe',
              label: 'for_non_login_users',
              nonInteraction: true,
            });
            mclOpen.post(
                // Url.PAYMENT.concat("/createStripeSubs"),
                Url.PAYMENT.concat("/registerStripePayment"),
                {
                  productID: product_id,
                  email: email,
                  password: password,
                  phone: phone,
                  b2b: b2b,
                  firstName: answer.firstName,
                  lastName: answer.lastName,
                  invitationID: INVITATION_ID,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                // localStorage.removeItem("accessToken");
                // localStorage.removeItem("tempEmail");
                // localStorage.setItem("tempEmail", tempEmail);
                window.location.href = res.data;
              });
        } else {
          ReactGA.event({
            category: 'pricing',
            action: 'submit_subscribe',
            label: 'subscribe_failed_missing_recaptcha',
            nonInteraction: true,
          });
          alert("Missing recaptcha token.");
        }
      // };

    };
    console.log('calling recaptchafirst!');
    recaptchaFirst();
  };

  const doSubscribe = async () => {
    let payload = {
      email,
      firstName: answer.firstName,
      lastName: answer.lastName,
      password,
      phone,
      answer,
      invitationID: INVITATION_ID,
      StripeID: userTempStripeID,
      SubID: userTempSubID,
      Plan: userTempPlan,
      };
    
    if (b2b) {
      payload = {...payload, b2b: b2b}
    }

    try {
        const response = await mclOpen.post(
        Url.SUBSCRIBE,
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      console.log(JSON.stringify(response?.data));
      setPassword("");
      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        console.log("No Server Response");
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Some fields need to be filled out");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle:
            "Please check and modify the entered information before resubmitting.",
        });
      } else if (err.response?.status === 409) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Account with the same email already exists");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle: "Account with the same email already exists.",
        });
      } else {
        // setErrMsg('Subscription Failed')
        console.log("Registration Failed", err);
        openNotification({
          message: "Server Error",
          status: "500",
          title: "500",
          subTitle: "Sorry, something went wrong.",
        });
      }
    }
  };

  const onFinish = (values) => {
    // ReactGA.event({
    //   action: select + "_survey_action",
    //   category: "survey_category",
    //   label: "survey_label",
    //   value: current,
    // });

    console.log("values", JSON.stringify(values));
    console.log('b2b value:', b2b);
    setEmail(TEMP_USER_ID ? userTempEmail : values.email);
    setPassword(values.password);
    setPhone(values.phone);
    setFirstName(values.firstName);
    setLastName(values.lastName);

    console.log("answers: " + firstName, lastName);

    if (values.b2b !== DEFAULT_B2B_OTHER) {
      if (b2b !== values.b2b) {
        setB2b(values.b2b);
      }
    }

    //doSubscribe()
    // setSubmit(true);
    setIsModalVisible(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // if (success)
  //   return (
  //     <Result
  //       status="success"
  //       title="Successfully subscribed to a MyCareLinq account!"
  //       subTitle={`An email with the confirmation link has been sent to ${email}.  Please click the link to complete the subscription`}
  //       extra={[
  //         <Button key="login" type="primary" shape="round">
  //           <Link to="/site/login">MyCareLinq Login</Link>
  //         </Button>,
  //       ]}
  //     />
  //   );

  return (
    <div>
      <Modal
        // title={
        //   "You are about to " +
        //   (subscribeNew ? "subscribe to a new plan" : "change plan") +
        //   ". Please read through the following contents."
        // }
        title={
          "You are about to avail of a new plan" +
          ". Please read through the following contents."
        }
        visible={isModalVisible}
        centered
        onCancel={() => {
          setIsModalVisible(false);
          // setTempEmail("");
        }}
        className="checkoutModal"
        footer={(null, null)}
      >
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          className="checkoutCollapse"
        >
          <Panel header="Terms of Use" key="1" className="max-h-fit">
            <ToUContent className="mt-5" />
          </Panel>
          {/* <Panel header="SUBSCRIPTION AGREEMENT" key="2" className="max-h-fit">
            <SAContent className="mt-5" />
          </Panel> */}
        </Collapse>
        <Row className="my-5 justify-center">
          <Checkbox
            onChange={() => {
              setTouChecked(!touChecked);
            }}
          >
            I acknowledge the <strong>term of use</strong>
          </Checkbox>
          {/* <Checkbox
            onChange={() => {
              setSaChecked(!saChecked);
            }}
          >
            I acknowledge the <strong>subscription agreement</strong>
          </Checkbox> */}
        </Row>
        <Row className="mb-5 justify-center">
          <form
            // action={Url.BASE.concat(Url.PAYMENT.concat("/createStripeSubs"))}
            method="POST"
            onSubmit={handleModalSubmit}
          >
            {/* <input type="hidden" name="productID" value={props.product_ID} />
            <input type="hidden" name="priceID" value={props.product_priceID} />
            <input type="hidden" name="price" value={props.product_price} /> */}
            {/* <Button disabled={!touChecked || !saChecked} htmlType="submit"> */}
            <Button disabled={!touChecked} htmlType="submit" 
                className={"mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white text-lg h-fit"}
            >
              continue
            </Button>
          </form>
        </Row>
      </Modal>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            b2b: b2b,
            // firstname: answer.firstName,
            // lastname: answer.lastName,
          }
        }
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col span={1} />
          <Col span={16}>
            <h3>Subscribe to Our Service</h3>
          </Col>
        </Row>
        <p />
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: TEMP_USER_ID ? false : true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            initialvalues={TEMP_USER_ID ? userTempEmail : ""}
            placeholder={TEMP_USER_ID ? userTempEmail : "E-mail"}
            disabled={TEMP_USER_ID}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Must be at least 10 charaters long!",
              min: 10,
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: false,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Input placeholder="Phone number" />
        </Form.Item>


        <Form.Item
          name="b2b"
          label="Employer"
          rules={[
            {
              required: true,
              message: "Please select your employer",
            },
          ]}
        >
          <Select defaultValue={b2b}  placeholder="Select your Employer">
            <Option value={DEFAULT_B2B_OTHER}>Other Employer</Option>
            {companies?.map((company, indexValue) => (
                        <Select.Option
                          key={indexValue}
                          value={company.key}
                        >
                          {company.label}
                        </Select.Option>
                      ))
            }
          </Select>
        </Form.Item>

        <Row>
          <Col span={1} />
          <Col span={16}>
            <h3>Your Details</h3>
          </Col>
        </Row>
        <p />
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your first name",
            },
          ]}
        >
          {/* <Input disabled={true} /> */}
          <Input placeholder="FirstName" />
        </Form.Item>
        <p />
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input your last name",
            },
          ]}
        >
          {/* <Input disabled={true} /> */}
          <Input placeholder="LastName" />
        </Form.Item>


        {/* {answer.reason === Care.Reason.INCARE ? (
          <>
            <Row>
              <Col span={1} />
              <Col span={16}>
                <h3>In Care Details</h3>
              </Col>
            </Row>
            <p />
            <Form.Item
              label="Name"
              name="incarename"
              rules={[
                {
                  required: false,
                  message: "Please input your last name",
                },
              ]}
            >
              <Button>
                {answer.incareFirstName} {answer.incareLastName}
              </Button>
            </Form.Item>
          </>
        ) : (
          <></>
        )} */}
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            {/* <Button
              type="primary"
              shape="round"
              onClick={() => setCurrent((current) => current - 1)}
            >
              Previous
            </Button> */}
            <Button type="primary" shape="round" htmlType="submit">
              Subscribe
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: (
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        /* extra={<Button type="primary">Back Home</Button>}*/
      />
    ),
  });
};

export default Register;
